import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import {
	Container,
	FormGroup,
	Label,
	Button,
	Form,
	Input,
	Col,
	Card,
	CardBody,
	CardTitle,
} from "reactstrap"
import { Link } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import * as url from "../../helpers/url_helper"
import { API_URL } from "../../helpers/api_helper";
import $ from "jquery"
import getBearToken from "../Utility/token";
class SettingEdit extends Component {
	constructor(props) {
		super(props)
		this.state = {
			store_domain: "",
			is_campaign_links: false,
			is_coupon_blocker: false,
			is_smart_discount: false,
			loading: false,
			store_details: props.location.data.store_details,
			store_status: "",
		}
	}
	updateItem = (e) => {
		this.setState({
			store_domain: e.store_domain,
			variable_charge: e.variable_charge,
			fixed_charge: e.fixed_charge,
		})
	}
	componentDidMount() {
		const { data } = this.props.location;
		if (!data) {
			return window.location.href = '/stores';
		}
		const { store_domain, store_details, store_details: { is_campaign_links, is_coupon_blocker, is_smart_discount }, store_status } = data;
		this.setState({
			store_domain,
			is_campaign_links,
			is_coupon_blocker,
			is_smart_discount,
			store_details: { ...store_details },
			store_status
		})
	}
	handleValidSubmit = (e) => {
		e.preventDefault();
		$(".loading").text("Loading ...").css('color', 'red');
		const { store_domain, is_campaign_links, is_coupon_blocker, is_smart_discount } = this.state;

		fetch(`${API_URL}${url.PUT_UPDATE_STORE}`, {
			method: 'put',
			headers: {
				'Authorization': `${getBearToken()}`,
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				store_domain,
				is_campaign_links,
				is_coupon_blocker,
				is_smart_discount
			})
		})
			.then(res => res.json())
			.then(data => {
				$(".loading").text("");
				this.setState({
					loading: true
				})
			})
	}

	handleInputChange = (e) => {
		const { name, checked } = e.target;
		this.setState({
			[name]: checked
		})
	}

	render() {
		const { store_domain, store_details, store_status, loading } = this.state;
		return (
			<React.Fragment>
				<div className="page-content">
					<MetaTags>
						<title>Veeper | AP - Settings Edit</title>
					</MetaTags>
					<Container fluid>
						{/* Render Breadcrumb */}
						<Breadcrumbs
							title={("Settings Edit")}
							breadcrumbItem={("Settings")}
						/>
						<Col md={12}>
							{loading && <div class="alert alert-success" role="alert">
								Setting information has been updated successful!
							</div>}
							<Card>
								<CardBody>

									<CardTitle className="h4"></CardTitle>
									<Link to={{ pathname: "/merchant-details", data: store_details, storeStatus: store_status }} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>

										<Button className="btn btn-dark">
											<i className="fa fa-arrow-left"></i> Store Details
										</Button>
									</Link>

									<Form onSubmit={this.handleValidSubmit}>
										<FormGroup className="row mb-4">
											<Label
												htmlFor="horizontal-store_domain-Input"
												className="col-sm-2 col-form-label text-md-right"
												style={{ textAlign: "center" }}
											>
												Store Domain
											</Label>
											<Col sm={4}>
												<h5 className="form-control">{store_domain}</h5>
											</Col>
										</FormGroup>
										<FormGroup className="row mb-4">
											<Label
												htmlFor="horizontal-campaign_links-Input"
												className="col-sm-2 col-form-label text-md-right"
												style={{ textAlign: "center" }}
											>
												Campaign Links
											</Label>
											<Col sm={4}>
												<Input
													value={this.state.is_campaign_links}
													type="checkbox"
													name="is_campaign_links"
													className="form-control"
													id="horizontal-campaign_links-Input"
													onChange={this.handleInputChange}
													checked={this.state.is_campaign_links}
												/>
											</Col>
										</FormGroup>

										<FormGroup className="row mb-4">
											<Label
												htmlFor="horizontal-coupon-blocker-Input"
												className="col-sm-2 col-form-label text-md-right"
												style={{ textAlign: "center" }}
											>
												Coupon Blocker
											</Label>
											<Col sm={4}>
												<Input
													value={this.state.is_coupon_blocker}
													type="checkbox"
													name="is_coupon_blocker"
													className="form-control"
													id="horizontal-coupon-blocker-Input"
													onChange={this.handleInputChange}
													checked={this.state.is_coupon_blocker}
												/>
											</Col>
										</FormGroup>

										<FormGroup className="row mb-4">
											<Label
												htmlFor="horizontal-smart-discount-Input"
												className="col-sm-2 col-form-label text-md-right"
												style={{ textAlign: "center" }}
											>
												Smart Discount
											</Label>
											<Col sm={4}>
												<Input
													value={this.state.is_smart_discount}
													type="checkbox"
													name="is_smart_discount"
													className="form-control"
													id="horizontal-smart-discount-Input"
													onChange={this.handleInputChange}
													checked={this.state.is_smart_discount}
												/>
											</Col>
										</FormGroup>

										<FormGroup className="row mb-4 justify-content-end">
											<Col sm={10}>
												<div>
													<Button
														type="submit"
														color="primary"
														className="w-md"
													>
														Update
													</Button> &nbsp; &nbsp; &nbsp; <span className="loading"></span>
												</div>
											</Col>
										</FormGroup>
									</Form>
								</CardBody>
							</Card>
						</Col>

					</Container>
				</div>
			</React.Fragment>
		)
	}
}

SettingEdit.propTypes = {
	t: PropTypes.any
}
export default SettingEdit
