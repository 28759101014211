import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import {
    Container,
    FormGroup,
    Label,
    Button,
    Form,
    Input,
    Col,
    Card,
    CardBody,
    CardTitle,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"
import * as url from "../../helpers/url_helper"
import { API_URL } from "../../helpers/api_helper";
import $ from "jquery"
import getBearToken from "../Utility/token";
    
class priceSelectorEdit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            store_domain: "",
            created_at: '',
            loading: false,
            store_details: {},
            store_status: "",
        }
    }
    updateItem = (e) => {
        // e.preventDefault();
        this.setState({
            store_domain: e.store_domain,
            created_at: e.created_at,
        })
    }
    formatDate = (date) => {
        const d = new Date(date);
        const month = (d.getMonth() + 1).toString().padStart(2, '0');
        const day = d.getDate().toString().padStart(2, '0');
        const year = d.getFullYear();
        return `${year}-${month}-${day}`;
    };
    componentDidMount() {
        const { data } = this.props.location;
        if (!data) {
            return window.location.href = '/stores';
        }
        const { store_domain, store_details, store_status } = data;
        const created_at = this.formatDate(store_details?.created_at)
        this.setState({
            store_domain,
            created_at ,
            store_details: { ...store_details },
            store_status
        })
    }
    handleValidSubmit = (e) => {
        e.preventDefault();
        const data = this.state;
        const { store_domain, created_at } = data;
        $(".loading").text("Loading ...").css('color', 'red');

        fetch(`${API_URL}${url.PUT_UPDATE_STORE}`, {
            method: 'put',
            headers: {
                'Authorization': `${getBearToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                store_domain,
                created_at,
            })
        })
            .then(res => res.json())
            .then(data => {
                $(".loading").text("");
                this.setState({
                    loading: true
                })
            })
    }

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }


    render() {
        const { store_domain, created_at, loading, store_details, store_status } = this.state;
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Veeper | AP - Store Info</title>
                    </MetaTags>
                    <Container fluid>
                        {/* Render Breadcrumb */}
                        <Breadcrumbs
                            title={("Store")}
                            breadcrumbItem={("Update Trial Info")}
                        />
                        <Col md={12}>
                            {loading && <div class="alert alert-success" role="alert">
                                store info has been updated successful!
                            </div>}
                            <Card>
                                <CardBody>
                                    <CardTitle className="h4"></CardTitle>
                                    <Link to={{ pathname: "/merchant-details", data: store_details, storeStatus: store_status }} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>

                                        <Button className="btn btn-dark">
                                            <i className="fa fa-arrow-left"></i> Store Details
                                        </Button>
                                    </Link>

                                    <Form onSubmit={this.handleValidSubmit}>
                                        <FormGroup className="row mb-4">
                                            <Label
                                                htmlFor="horizontal-store_domain-Input"
                                                className="col-sm-1 col-form-label text-md-right"
                                                style={{ textAlign: "center" }}
                                            >
                                                Store Domain
                                            </Label>
                                            <Col sm={2}>
                                                <Input
                                                    value={store_domain}
                                                    type="text"
                                                    name="store_domain"
                                                    className="form-control"
                                                    id="horizontal-store_domain-Input"
                                                    readOnly
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className="row mb-4">
                                            <Label
                                                htmlFor="horizontal-created_at-Input"
                                                className="col-sm-1 col-form-label text-md-right"
                                                style={{ textAlign: "center" }}
                                            >
                                                Created At
                                            </Label>
                                            <Col sm={2}>
                                                <Input
                                                    value={created_at}
                                                    type="date"
                                                    name="created_at"
                                                    className="form-control"
                                                    id="horizontal-created_at-Input"
                                                    onChange={this.handleInputChange}
                                                    required
                                                />
                                            </Col>
                                        </FormGroup>

                                        <FormGroup className="row justify-content-end">
                                            <Col sm={11}>
                                                <div>
                                                    <Button
                                                        type="submit"
                                                        color="primary"
                                                        className="w-md"
                                                    >
                                                        Update
                                                    </Button> &nbsp; &nbsp; &nbsp; <span className="loading"></span>
                                                </div>
                                            </Col>
                                        </FormGroup>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>

                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

priceSelectorEdit.propTypes = {
    t: PropTypes.any
}
export default priceSelectorEdit
