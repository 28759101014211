import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import $ from 'jquery'
import {
    Container,
    Row,
    Col,
} from "reactstrap"
import * as url from "../../helpers/url_helper"
import { API_URL } from "../../helpers/api_helper";
import { Link } from "react-router-dom"
import MerchantsList from "./MerchantsList"
import getBearToken from "../Utility/token";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { formatToShortHand } from "../../helpers/common_helper"

//i18n
import { withTranslation } from "react-i18next"
import { ToastContainer, toast } from "react-toastify";

class Stores extends Component {
    constructor(props) {
        super(props)
        this.state = {
            TotalMerchants: 0,
            MerchantsDetails: 0,
            loading: false,
            search: props.location.search ? props.location.search.split('=')[1] : ''
        }

        this.togglemodal.bind(this)
        this.togglesubscribemodal.bind(this)
    }

    formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
        });
    };

    sortByDate = (isoString) => {
        const date = new Date(isoString);

        const year = date.getFullYear().toString();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    notify_info = (message) => toast.info(`${message}`);
    notify_error = (message) => toast.error(`${message}`);
    notify_success = (message) => toast.success(`${message}`);

    async componentWillMount() {
        // this.setState({loading: true});
        $("#loadingIcon").text("Loading ...").css('color', 'red');
        try {
            const response = await fetch(`${API_URL}${url.GET_DASHBOARD_MERCHANTS}?search=${this.state.search}`, {
                method: 'get',
                headers: {
                    'Authorization': `${getBearToken()}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });
            const results = await response.json();
            if (response.ok) {
                let merchants_list = results?.data?.map(merchant => ({
                    store_name: merchant.store_name,
                    store_domain: <Link to={{ pathname: `https://${merchant.store_domain}` }} target="_blank" rel="noreferrer">{merchant.store_domain}</Link>,
                    link: <Link to={{ pathname: "/merchant-details", data: merchant, storeStatus: 'active' }} ><i className="fas fa-eye"></i> Details</Link>,
                    plan: merchant.plan ? `${merchant.plan} (${merchant.plan_status})` : "Not Updated",
                    recovered_revenue: <span onClick={() => this.disableClick2(merchant.recovered_revenue)}>${formatToShortHand(parseInt(merchant.recovered_revenue))}</span>,
                    created: <span onClick={() => this.disableClick(merchant.created_at)}>{this.formatDate(merchant.created_at)}</span>,
                    is_set_webpixel: merchant?.is_set_webpixel ? "Yes" : "No",
                    coupon_scanner: <Link to={{ pathname: "/coupon-scanner", store: merchant.store_domain }} ><i className="fas fa-eye"></i> View </Link>,
                }));

                const table_records = {
                    columns: [
                        {
                            label: "Name",
                            field: "store_name",
                            sort: "asc",
                            width: 150,
                        },
                        {
                            label: "Domain",
                            field: "store_domain",
                            sort: "asc",
                            width: 150,
                        },
                        {
                            label: "Plan",
                            field: "plan",
                            sort: "asc",
                            width: 270,
                        },
                        {
                            label: "Recovered Revenue",
                            field: "recovered_revenue",
                            sort: "asc",
                            width: 200,
                        },
                        {
                            label: "Installed",
                            field: "created",
                            width: 100,
                        },
                        {
                            label: "Webpixel",
                            field: "is_set_webpixel",
                            width: 100,
                        },
                        {
                            label: "Details",
                            field: "link",
                            width: 150,
                        },
                        {
                            label: "Coupon Scanner",
                            field: "coupon_scanner",
                            width: 150,
                        }
                    ],
                    rows: merchants_list
                }
                this.setState({
                    TotalMerchants: results?.data?.length || 0,
                    MerchantsDetails: table_records,
                    loading: true
                });

                this.notify_success("Stores Loaded successfully");

            } else if (response.status === 401) {
                this.notify_error(`Unauthorized: ${results.message || "You are not authorized to perform this action"}`);
                // redirect to login page
                window.location.href = "/logout";
            } else if (response.status === 500) {
                this.notify_error(`Server Error: ${results.message || "Something went wrong"}`);
            } else {
                this.notify_error(`Unexpected Error: ${response.status} - ${results.message || "Please try again"}`);
            }
        } catch (err) {
            this.notify_error("Auto BCL Deletion failed, Please check your network connection.");
        }
    }

    disableClick = () => {
        return false
    }

    disableClick2 = () => {
        return false
    }

    componentDidMount() {
        setTimeout(() => this.setState({ subscribemodal: false }), 2000);
    }

    togglemodal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }))
    }

    togglesubscribemodal = () => {
        this.setState(prevState => ({
            subscribemodal: !prevState.subscribemodal,
        }))
    }

    updateScript = () => {
        $(".loading").text(`Updating ...  `).css('color', 'red')
        fetch(`${API_URL}${url.GET_UPDATE_SCRIPT}`, {
            method: 'get',
            headers: {
                'Authorization': `${getBearToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(results => {
                // const {
                //     status,
                //     message,
                // } = results;
                $(".loading").text(``)
            }).catch(function (error) {
                console.log(error);
            });
    }

    render() {
        const {
            MerchantsDetails,
            loading
        } = this.state;

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Veeper | AP - Stores</title>
                    </MetaTags>
                    <Container fluid>
                        {/* Render Breadcrumb */}
                        <Breadcrumbs
                            title={this.props.t("Stores")}
                            breadcrumbItem={this.props.t("Stores")}
                        />
                        <ToastContainer />
                        <Row>
                            <Col xl={12}>
                                <MerchantsList data={MerchantsDetails} default_entries={200} />
                            </Col>
                        </Row>
                    </Container>
                </div>

            </React.Fragment>
        )

    }
}

Stores.propTypes = {
    t: PropTypes.any
}

export default withTranslation()(Stores)
