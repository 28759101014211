import React from 'react';
import PropTypes from 'prop-types';
import { Table, Button, Input } from 'reactstrap';

const ScannerList = ({ couponScanner, onUpdate, websiteUrls, siteUrl, handleInputChange }) => {
    return (
        <Table striped>
            <thead>
                <tr>
                    <th style={{ width: '5%' }}>ID</th>
                    <th style={{ width: '10%' }}>Name</th>
                    <th style={{ width: '25%' }}>Coupons</th>
                    <th style={{ width: '25%' }}>Client Visible URL</th>
                    <th style={{ width: '25%' }}>Website URLs</th>
                    <th style={{ width: '10%' }}>Action</th>
                </tr>
            </thead>
            <tbody>
                {couponScanner.map(({ id, name, coupons }, index) => (
                    <tr key={id}>
                        <td>{index + 1}</td>
                        <td>{name}</td>
                        <td>{coupons?.join(', ')}</td>
                        <td>
                            <Input
                                type="text"
                                name={`site_url_${id}`}
                                placeholder="Write here client visible url"
                                value={siteUrl[`site_url_${id}`] || ''}
                                onChange={handleInputChange}
                            />
                        </td>
                        <td>
                            <Input
                                type="textarea"
                                name={`website_urls_${id}`}
                                placeholder="Write website urls with comma separator"
                                value={websiteUrls[`website_urls_${id}`] || ''}
                                onChange={handleInputChange}
                                rows="3"
                                className="form-control"
                            />
                        </td>
                        <td>
                            <Button
                                color="primary"
                                onClick={() => onUpdate(id)} // Call the update function
                            >
                                Update
                            </Button>
                        </td>
                    </tr>
                ))}
                {
                    couponScanner.length === 0 && (
                        <tr>
                            <td colSpan="5" className="text-center">No coupon scanner found</td>
                        </tr>
                    )
                }
            </tbody>
        </Table>
    );
};

ScannerList.propTypes = {
    couponScanner: PropTypes.array.isRequired,
    onUpdate: PropTypes.func.isRequired,
    websiteUrls: PropTypes.object.isRequired,
    siteUrl: PropTypes.object, // Changed from PropTypes.object.isRequired to PropTypes.object
    handleInputChange: PropTypes.func.isRequired,
};

export default ScannerList;