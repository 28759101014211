import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import $ from 'jquery'
import {
  Container,
  Row,
  Col,
  Button
} from "reactstrap"
import * as url from "../../helpers/url_helper"
import { API_URL } from "../../helpers/api_helper";
import { ToastContainer, toast } from "react-toastify";
import getBearToken from "../Utility/token";

import LogsList from "./logsList";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

class Shoppers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      TotalLogs: 0,
      LogsDetails: [],
      loading: false
    }

    this.togglemodal.bind(this)
    this.togglesubscribemodal.bind(this)
  }

  notify_info = (message) => toast.info(`${message}`);
  notify_error = (message) => toast.error(`${message}`);
  notify_success = (message) => toast.success(`${message}`);

  async componentWillMount() {
    // this.setState({loading: true});
    $("#loadingIcon").text("Loading ...").css('color', 'red');
    try {
      const response = await fetch(`${API_URL}${url.GET_ERROR_LOGS}`, {
        method: 'get',
        headers: {
          'Authorization': `${getBearToken()}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
      const results = await response.json();

      if (response.ok) {
        const {
          data: logs,
        } = results;
        let log_list = logs.map((element, index) => ({
          id: index + 1,
          stack: element.stack,
          url: element.url ? element.url?.split('?')[0] : 'N/A',
          msg: element.message,
          date: element.created_at.split('T')[0],
        }));
        const data = {
          columns: [
            {
              label: "SN",
              field: "id",
              sort: "asc",
              width: '5%',
            },
            {
              label: "Message",
              field: "msg",
              sort: "asc",
              width: '50%',
            },
            {
              label: "Stack",
              field: "stack",
              sort: "asc",
              width: '25%',
            },

            {
              label: "URL",
              field: "url",
              sort: "asc",
              width: '25%',
            },
            {
              label: "Date",
              field: "date",
              sort: "asc",
              width: '5%',
            }
          ],
          rows: log_list,
        }

        this.setState({
          TotalLogs: logs.length,
          LogsDetails: data,
          loading: false
        });
        this.notify_success("Logs Loaded successfully");
      } else if (response.status === 401) {
        this.notify_error(`Unauthorized: ${results.message || "You are not authorized to perform this action"}`);
        // redirect to login page
        window.location.href = "/logout";
      } else if (response.status === 500) {
        this.notify_error(`Server Error: ${results.message || "Something went wrong"}`);
      } else {
        this.notify_error(`Unexpected Error: ${response.status} - ${results.message || "Please try again"}`);
      }
    } catch (err) {
      this.notify_error("Auto BCL Deletion failed, Please check your network connection.");
    }
  }

  componentDidMount() {
    setTimeout(() => this.setState({ subscribemodal: false }), 2000);

  }

  togglemodal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  togglesubscribemodal = () => {
    this.setState(prevState => ({
      subscribemodal: !prevState.subscribemodal,
    }))
  }

  clearLogs = async () => {
    console.log("Clearing logs");
    try {
      const response = await fetch(`${API_URL}${url.DELETE_ERROR_LOGS}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `${getBearToken()}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
      console.log(response);
      if (response.ok) {
        this.componentWillMount();
        this.notify_success("Logs cleared successfully");
      } else {
        this.notify_error("Error clearing logs");
      }
    } catch (err) {
      this.notify_error("Error clearing logs");
    }
  }

  render() {
    const {
      LogsDetails,
      TotalLogs,
    } = this.state;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Veeper | AP - Customers</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.props.t("Error Logs")}
              breadcrumbItem={this.props.t("Error Logs")}
            />
            <div className="d-flex justify-content-between">
              <p style={{ "paddingLeft": "34px" }}>{TotalLogs} Veeper error logs</p>
              <div style={{ "textAlign": "right", "paddingBottom": "10px" }}>
                <Button size="md" onClick={this.clearLogs} className="btn btn-danger waves-effect waves-light">Clear Logs</Button>
              </div>
            </div>
            <ToastContainer />
            <Row>
              <Col xl={12}>
                <LogsList logs={LogsDetails} />
              </Col>
            </Row>
          </Container>
        </div>

      </React.Fragment>
    )

  }
}

Shoppers.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(Shoppers)
