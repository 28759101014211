import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { Component } from "react"
import DiscountType from "./DiscountType"
import BlockedCoupon from "./BlockedCoupon"
import MerchantsList from "./MerchantsList";
import * as url from "../../helpers/url_helper"
import { API_URL } from "../../helpers/api_helper";
import BasicCountInfo from "../Dashboard/BasicCountInfo"
import Settings from './CommonSettings';
import ClientInfo from './ClientInfo';
import getBearToken from "../Utility/token";

import Breadcrumbs from "../../components/Common/Breadcrumb"
import { formatToShortHand } from "../../helpers/common_helper"
import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Button,
    Spinner,
} from "reactstrap"

class MerchantsDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            app_embed: "disabled",
            store_name: "",
            store_domain: "",
            store_logo: "",
            app_block: "",
            storefront: '3.0',

            dropdownOpen: false,
            coupon_list: "",
            storeStatus: "active",
            isLoading: false,
            isCouponLoading: false,
            variable_charge: 2,
            fixed_charge: 0,
            campaign_variable_charge: 2,
            campaign_fixed_charge: 0,
            store_details: {},
            totalRevenue: 0,
            campaignRevenue: 0,
            couponBlockerRevenue: 0,
            smartDiscountRevenue: 0,

            veeperROI: 0,
            recoveredRevenue: 0,
            uniqueCouponBlocked: 0,
            totalCouponsBlocked: 0,
            totalOrderProtected: 0,
            ratioOfOrdersProtected: 0,
            recoveredRevenuePerOrder: 0,
            revenueFromProtectedOrders: 0,
            hasError: false,
            blocked_orders: [],
            isBlockedOrderLoading: false,
            plan: "",
            plan_status: "",
            storefrontToken: '',
            cartLocation: '',
            total_fees: 0,
            install_status: 3,
        }
    }

    taggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        })
    }

    componentDidMount() {
        const { data, storeStatus } = this.props.location;
        if (!data) {
            return window.location.href = '/stores';
        }

        const {
            store_name,
            store_domain,
            store_logo,
            app_block,
            storefront,
            discount_type,
            max_discount,
            placement,
            variable_charge,
            fixed_charge,
            campaign_variable_charge,
            campaign_fixed_charge,
            plan,
            plan_status,
            cart_location,
            public_access_token,
        } = data;
        let store_details = { ...data };

        let app_block_status = (storefront == '3.0') ? "Not Access #" : (app_block === 0) ? "Storefront 2.0#" : (app_block === 1) ? "Storefront 1.0 # App block on Cart Page" : (app_block === 2) ? "Storefront 1.0#App block on Product Page" : "Storefront 1.0 # App block on both Cart and Product Page ";

        this.setState({
            store_details: { ...store_details },
            store_name,
            store_domain,
            store_logo,
            app_block: app_block_status,

            discount_type: (discount_type) ? discount_type : "",
            max_discount: (max_discount) ? max_discount : "",
            placement: (placement) ? placement : "",
            plan: (plan) ? plan : "",
            plan_status: (plan_status) ? plan_status : "",
            storeStatus: (storeStatus) ? storeStatus : "active",
            variable_charge: (variable_charge) ? Number(variable_charge) : 0,
            fixed_charge: (fixed_charge) ? Number(fixed_charge) : 0,
            campaign_variable_charge: (campaign_variable_charge) ? Number(campaign_variable_charge) : 0,
            campaign_fixed_charge: (campaign_fixed_charge) ? Number(campaign_fixed_charge) : 0,
            isLoading: true,
            isCouponLoading: true,
            cartLocation: JSON.stringify(cart_location),
            publicAccessToken: public_access_token ? public_access_token : "",
            total_fees: Number(store_details?.total_fees) || Number(store_details?.plan_info?.price) || 0
        });

        // // console.log('sdad',this.state.store_details)
        fetch(`${API_URL}${url.GET_MERCHANTS_DATA_FILTER}?shop=${store_domain}&filterType=ALL`, {
            method: 'get',
            headers: {
                'Authorization': `${getBearToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(res => {
                const { data, status } = res;
                if (!status) {
                    this.setState({
                        isLoading: false
                    })
                    return;
                }
                this.setState({
                    ...data,
                    isLoading: false
                })
            })
            .catch(err => {
                // console.log(err);
            });

        fetch(`${API_URL}${url.GET_COUPON_CODE}?shop=${store_domain}`, {
            method: 'get',
            headers: {
                'Authorization': `${getBearToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(res => {
                const { data, status } = res;
                if (!status) {
                    this.setState({
                        isCouponLoading: false
                    })
                    return;
                }

                let coupon_list = data.map(coupon => ({
                    id: coupon.id?.split('/').pop(),
                    title: coupon.codeDiscount.title,
                    status: coupon.codeDiscount.status,
                    button: coupon.codeDiscount.status === "ACTIVE" ? <Button color="danger" size='sm' onClick={() => this.handleClick(coupon.id, 'deactive')}> Disable </Button> : <Button color="success" onClick={() => this.handleClick(coupon.id, 'active')} size='sm'> Enable </Button>,
                }));

                const table_records = {
                    columns: [
                        {
                            label: "ID",
                            field: "id",
                            sort: "asc",
                        },
                        {
                            label: "Coupon Code",
                            field: "title",
                            sort: "asc",
                            width: 150
                        },
                        {
                            label: "Status",
                            field: "status",
                            sort: "asc",
                            width: 270
                        },
                        {
                            label: "Action",
                            field: "button",
                            sort: "asc",
                            width: 100
                        },
                    ],
                    rows: coupon_list
                }

                this.setState({
                    coupon_list: table_records,
                    isCouponLoading: false
                })
            }).catch(err => {
                // console.log(err);
            });

        fetch(`${API_URL}${url.GET_BLOCKED_ORDERS}?shop=${store_domain}`, {
            method: 'get',
            headers: {
                'Authorization': `${getBearToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(res => {
                const { data, status } = res;
                // console.log(data);
                if (!status) {
                    this.setState({
                        isBlockedOrderLoading: false
                    })
                    return;
                }

                const blockedOrders = data.map(({
                    order_no,
                    revenue,
                    created_at,
                    total,
                    coupon,
                    extensions,
                    applied_coupons,
                    is_on_bcl
                }) => ({
                    order_no,
                    recovered_revenue: "$ " + parseFloat(revenue).toFixed(2),
                    // month format YYYY-MM-DD
                    created_at: new Date(created_at).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }),
                    total: "$" + parseFloat(total).toFixed(2),
                    coupon,
                    applied_coupons,
                    is_on_bcl: applied_coupons?.split(',').map(ele => ele.toLocaleLowerCase().trim('')).includes(coupon.toLocaleLowerCase().trim('')) ? 'Yes' : 'No',
                    extensions: extensions.map(ele => ele).join(', '),
                }));

                const table_records = {
                    columns: [
                        {
                            label: "Order",
                            field: "order_no",
                            sort: "asc",
                        },
                        {
                            label: "Recovered Revenue",
                            field: "recovered_revenue",
                            sort: "asc",
                        },
                        {
                            label: "Date",
                            field: "created_at",
                        },
                        {
                            label: "Order Total",
                            field: "total",
                            sort: "asc",
                        },
                        {
                            label: "Applied Coupons",
                            field: "applied_coupons",
                            sort: "asc",
                        },
                        {
                            label: "IsOnBCL",
                            field: "is_on_bcl",
                            sort: "asc",
                        },
                        {
                            label: "Coupon",
                            field: "coupon",
                            sort: "asc",
                        },
                        {
                            label: "Extensions",
                            field: "extensions",
                            sort: "asc",
                        }
                    ],
                    rows: blockedOrders
                }

                this.setState({
                    blocked_orders: table_records,
                    isBlockedOrderLoading: false
                })
            })
    }

    handleClick = (id, status) => {
        // console.log({ id, status });
        const store_domain = this.state.store_domain;
        fetch(`${API_URL}${url.PUT_COUPON_CODE}`, {
            method: 'put',
            headers: {
                'Authorization': `${getBearToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                shop: store_domain,
                id,
                status
            })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data);
                if (!data.status) {
                    return;
                }
                const price_rule_status = (status === 'active') ? 'ACTIVE' : 'EXPIRED';
                // console.log({ price_rule_status });

                const coupon_list = this.state.coupon_list.rows.map(coupon => {
                    if (coupon.id === id) {
                        coupon.status = price_rule_status;
                        coupon.button = price_rule_status === "ACTIVE" ? <Button color="danger" size='sm' onClick={() => this.handleClick(coupon.id, 'deactive')}> Disable </Button> : <Button color="success" className='btn btn-sm' onClick={() => this.handleClick(coupon.id, 'active')}> Enable </Button>;
                    }
                    return coupon;
                });

                // console.log(coupon_list);
                this.setState({
                    ...this.state,
                    coupon_list: { ...this.state.coupon_list, ...coupon_list }
                })
            })
    }

    changeValue = (value) => {
        const store_domain = this.state.store_domain;
        fetch(`${API_URL}${url.GET_MERCHANTS_DATA_FILTER}?shop=${store_domain}&filterType=${value}`, {
            method: 'get',
            headers: {
                'Authorization': `${getBearToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(response => {
                const { data } = response;
                this.setState({ ...this.state, ...data })
            }).catch(err => {
                // console.log(err);
            })
    }

    handleActionProduct = (shop, metafield_key, metafield_value) => {
        this.setState({
            isLoading: true
        })
        fetch(`${API_URL}${url.PUT_PRODUCTS}`, {
            method: 'put',
            headers: {
                'Authorization': `${getBearToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                shop: shop,
                metafield_key: metafield_key,
                metafield_value: metafield_value
            })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data);
                this.setState({
                    isLoading: false
                })
            })
    }

    handleActionCollections = (shop, metafield_key, metafield_value) => {
        this.setState({
            isLoading: true
        })
        fetch(`${API_URL}${url.PUT_STORE_COLLECTIONS}`, {
            method: 'put',
            headers: {
                'Authorization': `${getBearToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                shop: shop,
                metafield_key: metafield_key,
                metafield_value: metafield_value
            })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data);
                this.setState({
                    isLoading: false
                })
            })
    }

    render() {
        const {
            store_details,
            storeStatus,
            store_name,
            store_domain,
            store_logo,
            coupon_list,
            isLoading,
            isCouponLoading,

            totalRevenue,
            campaignRevenue,
            smartDiscountRevenue,

            veeperROI,
            recoveredRevenue,
            uniqueCouponBlocked,
            totalCouponsBlocked,
            totalOrderProtected,
            ratioOfOrdersProtected,
            recoveredRevenuePerOrder,
            revenueFromProtectedOrders,
            blocked_orders,
            isBlockedOrderLoading,
            cartLocation,
            total_fees,
            publicAccessToken
        } = this.state;

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Veeper | AP - Merchant Details</title>
                    </MetaTags>
                    <Container fluid>
                        {/* Render Breadcrumb */}
                        <Breadcrumbs
                            title={("Merchant Details")}
                            breadcrumbItem={`${store_name}`}
                        />
                        <Row style={{ margin: "10px 0" }}>
                            <Col sm="3">
                                <img src={store_logo} width={80} height={80} />
                                {isLoading ? <Spinner color="primary" /> : ' '}
                            </Col>
                            <Col sm="3" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                {storeStatus === 'active' ?
                                    <div>
                                        <Button className="btn btn-danger btn-md" onClick={() => this.handleActionCollections(store_domain, 'veeper_status', 'disabled')}> Disable Collections</Button> {' '}
                                        <Button className="btn btn-success btn-md" onClick={() => this.handleActionCollections(store_domain, 'veeper_status', 'enabled')}>Enable Collections</Button>
                                    </div>
                                    : null}
                            </Col>
                            <Col sm="3" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                {storeStatus === 'active' ?
                                    <div>
                                        <Button className="btn btn-danger btn-md" onClick={() => this.handleActionProduct(store_domain, 'veeper_status', 'disabled')}> Disable Products</Button> {' '}
                                        <Button className="btn btn-success btn-md" onClick={() => this.handleActionProduct(store_domain, 'veeper_status', 'enabled')}>Enable Products</Button>
                                    </div>
                                    : null}
                            </Col>
                            <Col sm={3} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <Dropdown
                                    isOpen={this.state.singlebtn1}
                                    toggle={() =>
                                        this.setState({
                                            singlebtn1: !this.state.singlebtn1,
                                        })
                                    }
                                >
                                    <DropdownToggle className="btn btn-secondary" caret>
                                        Timeline{" "}
                                        <i className="mdi mdi-chevron-down"></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => this.changeValue('ALL')} >All</DropdownItem>
                                        <DropdownItem onClick={() => this.changeValue('THIS_MONTH')} >Current Month</DropdownItem>
                                        <DropdownItem onClick={() => this.changeValue('LAST_MONTH')} >Last Month</DropdownItem>
                                        <DropdownItem onClick={() => this.changeValue('THIS_YEAR')} >Current Year</DropdownItem>
                                        <DropdownItem onClick={() => this.changeValue('LAST_YEAR')} >Last Year</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </Col>
                        </Row>
                        <Row>
                            <BasicCountInfo store_domain={store_domain} data={"$ " + formatToShortHand(totalRevenue)} colSize="3" title="Total Revenue" />
                            <BasicCountInfo store_domain={store_domain} data={"$ " + formatToShortHand(campaignRevenue)} colSize="3" title="Campaign Revenue" />
                            <BasicCountInfo store_domain={store_domain} data={"$ " + formatToShortHand(recoveredRevenue)} colSize="3" title="Recovered Revenue" />
                            <BasicCountInfo store_domain={store_domain} data={"$ " + formatToShortHand(smartDiscountRevenue)} colSize="3" title="Smart Discount Revenue" />
                        </Row>

                        <Row>
                            <BasicCountInfo store_domain={store_domain} data={totalOrderProtected} colSize="3" title="Total Orders Protected" />
                            <BasicCountInfo store_domain={store_domain} data={"$" + formatToShortHand(Math.ceil(Number(recoveredRevenuePerOrder)))} colSize="3" title="Recovered Revenue Per Order" />
                            <BasicCountInfo store_domain={store_domain} data={"$ " + formatToShortHand(Math.ceil(Number(revenueFromProtectedOrders)))} colSize="3" title="Revenue from Protected Orders" />
                            <BasicCountInfo store_domain={store_domain} data={ratioOfOrdersProtected + "%"} colSize="3" title="Ratio of Orders Protected" />
                        </Row>
                        <Row>
                            <BasicCountInfo store_domain={store_domain} data={totalCouponsBlocked} colSize="3" title="Total Coupons Blocked" />
                            <BasicCountInfo store_domain={store_domain} data={uniqueCouponBlocked} colSize="3" title="Unique Coupons Blocked" />
                            <BasicCountInfo store_domain={store_domain} data={veeperROI + "%"} colSize="3" title="Veeper ROI " />
                            <BasicCountInfo field="total_fees" store_domain={store_domain} data={total_fees} store_details={store_details} store_status={storeStatus} edit={true} colSize="3" linkUrl="placement-edit" title="Total Fees" />
                        </Row>
                        {storeStatus === 'active' &&
                            <Row>
                                <BasicCountInfo field="public_access_token" store_domain={store_domain} data={publicAccessToken} store_details={store_details} store_status={storeStatus} edit={true} colSize="3" linkUrl="placement-edit" title="Storefront Token [Headless]" />
                                <BasicCountInfo field="cart_location" store_domain={store_domain} data={cartLocation} store_details={store_details} store_status={storeStatus} edit={true} colSize="3" linkUrl="placement-edit" title="CartId Location [Headless]" />
                                <DiscountType store_domain={store_domain} store_details={store_details} store_status={storeStatus} is_campaign={true} colSize="3" title="Campaign Metafields" />
                                <DiscountType store_domain={store_domain} store_details={store_details} store_status={storeStatus} is_campaign={false} is_selector={true} colSize="3" title="Upgrade Created At" />
                            </Row>
                        }
                        <Row>
                            <Settings store_domain={store_domain} store_details={store_details} store_status={storeStatus} title="Settings" colSize="3" />
                            <BlockedCoupon store_domain={store_domain} store_details={store_details} store_status={storeStatus} title="Subscription Plan" colSize="3" is_plan={true} />
                            <BlockedCoupon store_domain={store_domain} store_details={store_details} store_status={storeStatus} is_campaign={false} is_selector={true} colSize="3" title="Blocked Coupon List" />
                            <ClientInfo store_details={store_details} title="Client Info" colSize="3" />
                        </Row>
                        {storeStatus === 'active' &&
                            <Row>
                                <Col xl={8}>
                                    <h4 className="header-title mt-4">Coupon Blocker List {isBlockedOrderLoading ? <Spinner color="primary" /> : ' '}</h4>
                                    <MerchantsList data={blocked_orders} option={[20, 30, 40, 60]} default_entries={20} title='Blocked Orders' />
                                </Col>
                                <Col xl={4}>
                                    <h4 className="header-title mt-4">Coupons {isCouponLoading ? <Spinner color="primary" /> : ' '}</h4>
                                    <MerchantsList data={coupon_list} option={[20, 30, 40, 60]} default_entries={20} title='List of Discounts' />
                                </Col>
                            </Row>
                        }

                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

MerchantsDetails.propTypes = {
    t: PropTypes.any
}
export default MerchantsDetails
