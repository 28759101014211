import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, } from "reactstrap"

export default class ClientInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dropdownOpen: false,
    }
    this.toggledropdown = this.toggledropdown.bind(this)
  }

  toggledropdown() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }))
  }
  render() {
    const { title, colSize, store_details } = this.props;

    return (
      <React.Fragment>
        <Col xl={colSize}>
          <Card>
            <CardBody>
              <div className="d-flex flex-wrap d-between-flex" >
                <h3 className="card-title mb-3 me-2">{title}</h3>
              </div>
              <div>
                <table className="table">
                  <tbody>
                    {store_details?.full_name && (
                      <tr>
                        <td>Full Name</td>
                        <td style={{ color: "Highlight" }}>{store_details.full_name}</td>
                      </tr>
                    )}

                    {store_details?.phone && (
                      <tr>
                        <td>Phone</td>
                        <td style={{ color: "Highlight" }}>{store_details.phone}</td>
                      </tr>
                    )}
                    {store_details?.email && (
                      <tr>
                        <td>Email</td>
                        <td style={{ color: "Highlight" }}>{store_details.email}</td>
                      </tr>
                    )}
                    {store_details?.website && (
                      <tr>
                        <td>Website</td>
                        <td>
                          <a href={`https://${store_details.website}`} target="_blank" rel="noopener noreferrer">
                            {store_details.website}
                          </a>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

            </CardBody>
          </Card>
        </Col>
      </React.Fragment >
    )
  }
}
