import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import $ from 'jquery'
import {
    Container,
    Row,
    Col,
} from "reactstrap"
import * as url from "../../helpers/url_helper"
import { API_URL } from "../../helpers/api_helper";
import { Link } from "react-router-dom"
import MerchantsList from "./MerchantsList"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { ToastContainer, toast } from "react-toastify";
import getBearToken from "../Utility/token";

class UninstalledStores extends Component {
    constructor(props) {
        super(props)
        this.state = {
            TotalMerchants: 0,
            MerchantsDetails: 0,
            loading: false
        }

        this.togglemodal.bind(this)
        this.togglesubscribemodal.bind(this)
    }

    notify_info = (message) => toast.info(`${message}`);
    notify_error = (message) => toast.error(`${message}`);
    notify_success = (message) => toast.success(`${message}`);

    async componentWillMount() {
        try {
            $("#loadingIcon").text("Loading ...").css('color', 'red');
            const response = await fetch(`${API_URL}${url.GET_DASHBOARD_MERCHANTS}?install_status=0`, {
                method: 'get',
                headers: {
                    'Authorization': `${getBearToken()}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });
            const results = await response.json();
            if (response.ok) {
                const {
                    status,
                    data: merchantsDetails,
                } = results;
                let merchants_list = merchantsDetails.map(merchant => ({

                    store_name: merchant.store_name,
                    store_domain: <Link to={{ pathname: `https://${merchant.store_domain}` }} target="_blank" rel="noreferrer">{merchant.store_domain}</Link>,
                    link: <Link to={{ pathname: "/merchant-details", data: merchant, storeStatus: 'inactive' }} ><i className="fas fa-eye"></i> Details</Link>,
                    transactions: merchant.total_orders,
                    sales: '$' + merchant.total_cost,
                    discount: '$' + merchant.total_discount,
                    revenue: '$' + merchant.total,
                    created: new Date(merchant.created_at).getDate() + "/" + new Date(merchant.created_at).getMonth() + "/" + new Date(merchant.created_at).getFullYear(),
                    script: (merchant.update_script) ? new Date(merchant.update_script).getDate() + "/" + new Date(merchant.update_script).getMonth() + "/" + new Date(merchant.update_script).getFullYear() : "Not Updated",
                    theme: (merchant?.storefront) ? (merchant.storefront == '2.0') ? 'UI Block' : "SDK" : "Not Updated",
                }));

                const data = {
                    columns: [
                        {
                            label: "Store Name",
                            field: "store_name",
                            sort: "asc",
                            width: 150,
                        },
                        {
                            label: "Store Domain",
                            field: "store_domain",
                            sort: "asc",
                            width: 150,
                        },
                        {
                            label: "Details",
                            field: "link",
                            width: 150,
                        },
                        {
                            label: "Transactions",
                            field: "transactions",
                            sort: "asc",
                            width: 270,
                        },
                        {
                            label: "Sales",
                            field: "sales",
                            sort: "asc",
                            width: 200,
                        },
                        {
                            label: "Discount",
                            field: "discount",
                            sort: "asc",
                            width: 100,
                        },
                        {
                            label: "Revenue",
                            field: "revenue",
                            sort: "asc",
                            width: 150,
                        },
                        {
                            label: "Installed",
                            field: "created",
                            sort: "asc",
                            width: 100,
                        },
                        {
                            label: "Script",
                            field: "script",
                            sort: "asc",
                            width: 100,
                        },
                        {
                            label: "Theme",
                            field: "theme",
                            sort: "asc",
                            width: 100,
                        },
                    ],
                    rows: merchants_list,
                }
                this.setState({
                    TotalMerchants: merchantsDetails.length,
                    MerchantsDetails: data,
                    loading: true
                })
                this.notify_success("Ex-Stores Loaded successfully");
            } else if (response.status === 401) {
                this.notify_error("Unauthorized: Please check your network connection.");
                // redirect to login page
                window.location.href = "/logout";
            } else if (response.status === 500) {
                this.notify_error("Server Error: Please check your network connection.");
            } else {
                this.notify_error("Please check your network connection.");
            }
        } catch (err) {
            this.notify_error("Please check your network connection.");
        }
    }

    componentDidMount() {
        setTimeout(() => this.setState({ subscribemodal: false }), 2000);

    }

    togglemodal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }))
    }

    togglesubscribemodal = () => {
        this.setState(prevState => ({
            subscribemodal: !prevState.subscribemodal,
        }))
    }

    updateScript = () => {
        $(".loading").text(`Updating ...  `).css('color', 'red')
        fetch(`${API_URL}${url.GET_UPDATE_SCRIPT}`, {
            method: 'get',
            headers: {
                    'Authorization': `${getBearToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(results => {
                $(".loading").text(``)
            }).catch(function (error) {
                console.log(error);
            });
    }

    render() {
        const {
            MerchantsDetails,
            loading
        } = this.state;

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Veeper | AP - Stores</title>
                    </MetaTags>
                    <Container fluid>
                        {/* Render Breadcrumb */}
                        <Breadcrumbs
                            title={this.props.t("Ex-Stores")}
                            breadcrumbItem={this.props.t("Ex-Stores")}
                        />
                        <ToastContainer />

                        <Row>
                            <Col xl={12}>
                                <MerchantsList data={MerchantsDetails} />
                            </Col>
                        </Row>
                    </Container>
                </div>

            </React.Fragment>
        )

    }
}

UninstalledStores.propTypes = {
    t: PropTypes.any
}

export default withTranslation()(UninstalledStores)