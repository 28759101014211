import React from "react"
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody } from "reactstrap"
import "./datatables.scss"

const WebsiteList = ({ customers }) => {
  // constructor(props) {
  //   super(props)
  //   this.state = {}
  // }
  console.log(customers)


  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>

              <MDBDataTable responsive bordered data={customers} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default WebsiteList
