import React from "react"
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import "./datatables.scss"

const LogsList = ({
  logs,
  title = "Error Logs",
  option = [10, 20, 30, 40, 60, 80, 100, 200],
  default_entries = 20,
  displayEntries = true,
  searching = true,
  paging = true,
}) => {

  console.log(logs)

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardTitle className="bg-light border-bottom p-3 mb-0">
              <i className="mdi mdi-account-group-outline font-18 mr-2"></i> {title}
            </CardTitle>
            <CardBody>
              <MDBDataTable
                responsive bordered
                data={logs}
                entriesOptions={option}
                entries={default_entries}
                displayEntries={displayEntries}
                searching={searching}
                paging={paging}
                responsiveLg={true}
                responsiveMd={true}
                theadColor="primary"
                tbodyColor="light"
                hover={true}
                sortable={false}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default LogsList
