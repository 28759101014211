import React, { Component } from "react"
import { Button } from "reactstrap"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import $ from 'jquery'
import {
  Container,
  Row,
  Col,
  Modal,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap"
import * as url from "../../helpers/url_helper"
import { API_URL } from "../../helpers/api_helper";
import { ToastContainer, toast } from "react-toastify";
import getBearToken from "../Utility/token";

import WebsiteList from "./WebsiteList";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

class ScannerWebsite extends Component {
  constructor(props) {
    super(props)
    this.state = {
      TotalCustomers: 0,
      CustomersDetails: 0,
      loading: false,
      modal_standard_create: false,
      modal_standard_edit: false,
      id: "",
      name: "",
      icon: "",
      contacts: "",
      help: "",
      website: "",
      position: 1,
      is_active: true,
    }

    this.togglemodal.bind(this)
    this.togglesubscribemodal.bind(this)
  }

  notify_info = (message) => toast.info(`${message}`);
  notify_error = (message) => toast.error(`${message}`);
  notify_success = (message) => toast.success(`${message}`);

  async componentWillMount() {
    // this.setState({loading: true});
    $("#loadingIcon").text("Loading ...").css('color', 'red');
    try {
      const response = await fetch(`${API_URL}${url.COUPON_SCANNER_WEBSITE}`, {
        method: 'get',
        headers: {
          'Authorization': `${getBearToken()}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
      const results = await response.json();

      if (response.ok) {
        let website_list = results?.data.map(({ 
          id, name, icon, is_active, created_at, contacts, help, website, position,
        }) => ({
          id,
          name,
          contacts,
          help,
          website,
          position,
          icon: icon ? <img src={icon} alt="icon" style={{ width: '40px', height: '40px' }} /> : '',
          is_active: is_active ? "Active" : "Inactive",
          created_at: new Date(created_at).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }),
          action: <button className="btn btn-primary" onClick={() => this.edit_scanner_website(id, name, icon, is_active, contacts, help, website, position, created_at)}>Edit</button>
        }));
        const records = {
          columns: [
            {
              label: "Name",
              field: "name",
              sort: "asc",
              width: 150,
            },
            {
              label: "Icon",
              field: "icon",
              sort: "asc",
              width: 150,
            },
            {
              label: "Contacts",
              field: "contacts",
              sort: "asc",
              width: 150,
            },

            {
              label: "Help",
              field: "help",
              sort: "asc",
              width: 150,
            },
            {
              label: "Website",
              field: "website",
              sort: "asc",
              width: 150,
            },
            {
              label: "Position",
              field: "position",
              sort: "asc",
              width: 150,
            },
            {
              label: "Status",
              field: "is_active",
              sort: "asc",
              width: 150,
            },
            {
              label: "Created At",
              field: "created_at",
              sort: "asc",
              width: 270,
            },
            {
              label: "Action",
              field: "action",
              sort: "asc",
              width: 200,
            }
          ],
          rows: website_list,
        }

        this.setState({
          TotalCustomers: results?.data.length,
          CustomersDetails: records,
          loading: true
        });
        this.notify_success("Scanner Website Loaded successfully");
      } else if (response.status === 401) {
        this.notify_error(`Unauthorized: ${results.message || "You are not authorized to perform this action"}`);
        // redirect to login page
        window.location.href = "/logout";
      } else if (response.status === 500) {
        this.notify_error(`Server Error: ${results.message || "Something went wrong"}`);
      } else {
        this.notify_error(`Unexpected Error: ${response.status} - ${results.message || "Please try again"}`);
      }
    } catch (err) {
      this.notify_error("Auto BCL Deletion failed, Please check your network connection.");
    }
  }

  componentDidMount() {
    setTimeout(() => this.setState({ subscribemodal: false }), 2000);

  }

  togglemodal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  togglesubscribemodal = () => {
    this.setState(prevState => ({
      subscribemodal: !prevState.subscribemodal,
    }))
  }

  create_scanner_website = () => {
    this.setState(prevState => ({
      name: "",
      icon: "",
      is_active: false,
      contacts: "",
      help: "",
      website: "",
      position: 1,
      modal_standard_create: !prevState.modal_standard_create,
    }))
  }

  edit_scanner_website = (id, name, icon, is_active, contacts, help, website, position) => {
    this.setState(prevState => ({
      id,
      name,
      icon,
      is_active,
      contacts,
      help,
      website,
      position,
      modal_standard_edit: !prevState.modal_standard_edit,
    }))
  }

  handleInputChange = (e) => {
    if (e.target.name === "is_active") {
      this.setState({ [e.target.name]: !this.state[e.target.name] });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = this.state;
      const { 
        name, 
        icon, 
        is_active,
        contacts,
        help,
        website,
        position 
      } = data;
      
      const response = await fetch(`${API_URL}${url.COUPON_SCANNER_WEBSITE}`, {
        method: 'post',
        headers: {
          'Authorization': `${getBearToken()}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name, icon, is_active, contacts, help, website, position: parseInt(position) })
      });
      const result = await response.json();
      if (response.ok) {
        this.notify_success("Scanner Website created successfully");
      } else {
        this.notify_error(`${result.message}`);
      }
      this.setState({
        modal_standard_create: false,
      })
      // reload the page
      window.location.reload();
    } catch (err) {
      this.notify_error("Scanner Website creation failed, Please check your network connection.");
    }
  }

  updateScannerWebsite = async (e) => {
    e.preventDefault();
    try {
      const data = this.state;
      const { id, name, icon, is_active, contacts, help, website, position } = data;
      const response = await fetch(`${API_URL}${url.COUPON_SCANNER_WEBSITE}`, {
        method: 'put',
        headers: {
          'Authorization': `${getBearToken()}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ id, name, icon, is_active, contacts, help, website, position: parseInt(position) })
      });
      const result = await response.json();
      if (response.ok) {
        this.notify_success("Scanner Website updated successfully");
      } else {
        this.notify_error(`${result.message}`);
      }
      this.setState({
        modal_standard_edit: false,
      })
      // reload the page
      window.location.reload();
    } catch (err) {
      console.log({ err });
      this.notify_error("Scanner Website update failed, Please check your network connection.");
    }
  }

  render() {
    const {
      CustomersDetails,
    } = this.state;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Veeper | AP - Customers</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.props.t("Scanner Website")}
              breadcrumbItem={this.props.t("Scanner Website")}
            />
            <ToastContainer />
            <Row>
              <div className="" style={{ "textAlign": "right", "paddingBottom": "10px" }}>
                <span className="loading"></span>
                <Button
                  color="primary"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={this.create_scanner_website}
                  data-toggle="modal"
                  data-target="#myModal"
                >Create</Button>
              </div>
              <Col xl={12}>
                <WebsiteList customers={CustomersDetails} />
              </Col>
            </Row>
            <Modal
              isOpen={this.state.modal_standard_create}
              toggle={this.create_scanner_website}
              size='lg'
            >
              <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                  Create Scanner Website
                </h5>
                <button
                  type="button"
                  onClick={() =>
                    this.setState({ modal_standard_create: false })
                  }
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <Card>
                  <CardBody>
                    <Form onSubmit={this.handleSubmit}>
                      <FormGroup className="row mb-4">
                        <Label
                          htmlFor="horizontal-name-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Name
                        </Label>
                        <Col sm={8}>
                          <Input
                            type="text"
                            name="name"
                            placeholder="Name"
                            className="form-control"
                            id="horizontal-name-Input"
                            value={this.state?.name}
                            onChange={this.handleInputChange}
                            required
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row mb-4">
                        <Label
                          htmlFor="horizontal-handle-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Icon
                        </Label>
                        <Col sm={8}>
                          <Input
                            type="text"
                            name="icon"
                            placeholder="Icon"
                            className="form-control"
                            id="horizontal-icon-Input"
                            value={this.state?.icon}
                            onChange={this.handleInputChange}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup className="row mb-4">
                        <Label
                          htmlFor="horizontal-name-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Contacts
                        </Label>
                        <Col sm={8}>
                          <Input
                            type="text"
                            name="contacts"
                            placeholder="Please write email"
                            className="form-control"
                            id="horizontal-name-Input"
                            value={this.state?.contacts}
                            onChange={this.handleInputChange}
                            required
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row mb-4">
                        <Label
                          htmlFor="horizontal-handle-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Help
                        </Label>
                        <Col sm={8}>
                          <Input
                            type="text"
                            name="help"
                            placeholder="Help Text"
                            className="form-control"
                            id="horizontal-icon-Input"
                            value={this.state?.help}
                            onChange={this.handleInputChange}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row mb-4">
                        <Label
                          htmlFor="horizontal-name-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Website
                        </Label>
                        <Col sm={8}>
                          <Input
                            type="text"
                            name="website"
                            placeholder="domain of website"
                            className="form-control"
                            id="horizontal-name-Input"
                            value={this.state?.website}
                            onChange={this.handleInputChange}
                            required
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row mb-4">
                        <Label
                          htmlFor="horizontal-handle-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Position
                        </Label>
                        <Col sm={8}>
                          <Input
                            type="number"
                            name="position"
                            placeholder="Position of the list"
                            className="form-control"
                            id="horizontal-icon-Input"
                            value={this.state?.position}
                            onChange={this.handleInputChange}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup className="row mb-4">
                        <Label
                          htmlFor="horizontal-email-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Status
                        </Label>
                        <Col sm={8}>
                          <Input
                            type="checkbox"
                            name="is_active"
                            placeholder="Status"
                            className="form-control"
                            id="horizontal-is_active-Input"
                            value={Boolean(this.state?.is_active)}
                            onChange={this.handleInputChange}
                            checked={Boolean(this.state?.is_active)}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row justify-content-end">
                        <Col sm={9}>
                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              className="w-md"
                            >
                              Submit
                            </Button>
                          </div>
                        </Col>
                      </FormGroup>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>

            <Modal
              isOpen={this.state.modal_standard_edit}
              toggle={this.edit_scanner_website}
              size='lg'
            >
              <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                  Edit Scanner Website
                </h5>
                <button
                  type="button"
                  onClick={() =>
                    this.setState({ modal_standard_edit: false })
                  }
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-body">
                <Card>
                  <CardBody>
                    <Form onSubmit={this.updateScannerWebsite}>
                      <FormGroup className="row mb-4">
                        <Label
                          htmlFor="horizontal-name-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Name
                        </Label>
                        <Col sm={8}>
                          <Input
                            type="text"
                            name="name"
                            placeholder="Auto BCL Name"
                            className="form-control"
                            id="horizontal-name-Input"
                            value={this.state?.name}
                            onChange={this.handleInputChange}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row mb-4">
                        <Label
                          htmlFor="horizontal-handle-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Icon
                        </Label>
                        <Col sm={8}>
                          <Input
                            type="text"
                            name="icon"
                            placeholder="Icon"
                            className="form-control"
                            id="horizontal-icon-Input"
                            value={this.state?.icon}
                            onChange={this.handleInputChange}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row mb-4">
                        <Label
                          htmlFor="horizontal-name-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Contacts
                        </Label>
                        <Col sm={8}>
                          <Input
                            type="text"
                            name="contacts"
                            placeholder="Please write email"
                            className="form-control"
                            id="horizontal-name-Input"
                            value={this.state?.contacts}
                            onChange={this.handleInputChange}
                            required
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row mb-4">
                        <Label
                          htmlFor="horizontal-handle-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Help
                        </Label>
                        <Col sm={8}>
                          <Input
                            type="text"
                            name="help"
                            placeholder="Help Text"
                            className="form-control"
                            id="horizontal-icon-Input"
                            value={this.state?.help}
                            onChange={this.handleInputChange}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row mb-4">
                        <Label
                          htmlFor="horizontal-name-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Website
                        </Label>
                        <Col sm={8}>
                          <Input
                            type="text"
                            name="website"
                            placeholder="domain of website"
                            className="form-control"
                            id="horizontal-name-Input"
                            value={this.state?.website}
                            onChange={this.handleInputChange}
                            required
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row mb-4">
                        <Label
                          htmlFor="horizontal-handle-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Position
                        </Label>
                        <Col sm={8}>
                          <Input
                            type="number"
                            name="position"
                            placeholder="Position of the list"
                            className="form-control"
                            id="horizontal-icon-Input"
                            value={this.state?.position}
                            onChange={this.handleInputChange}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup className="row mb-4">
                        <Label
                          htmlFor="horizontal-is_active-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Status
                        </Label>
                        <Col sm={8}>
                          <Input
                            type="checkbox"
                            name="is_active"
                            placeholder="Status"
                            className="form-control"
                            id="horizontal-is_active-Input"
                            onChange={this.handleInputChange}
                            checked={this.state?.is_active}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup className="row justify-content-end">
                        <Col sm={9}>
                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              className="w-md"
                            >
                              Update
                            </Button>
                          </div>
                        </Col>
                      </FormGroup>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>
          </Container>
        </div>

      </React.Fragment>
    )

  }
}

ScannerWebsite.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(ScannerWebsite)
