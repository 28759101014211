import React, { Component } from "react";
import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';
import $ from 'jquery';
import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import * as url from "../../helpers/url_helper";
import { API_URL } from "../../helpers/api_helper";
import { ToastContainer, toast } from "react-toastify";
import ScannerList from "./ScannerList";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import getBearToken from "pages/Utility/token";

class CouponScanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            TotalCustomers: 0,
            couponScanner: [],
            loading: false,
            store: '',
            siteUrl: {},
            websiteUrls: {}, // Store website URLs in an object
        };
    }

    notify = (type, message) => {
        switch (type) {
            case 'info':
                toast.info(message);
                break;
            case 'error':
                toast.error(message);
                break;
            case 'success':
                toast.success(message);
                break;
            default:
                break;
        }
    };

    handleInputChange = (e) => {
        const { name, value } = e.target;
        console.log({ name, value });
        const [key, id] = name.split('_');
        console.log({ key, id });
        if (key === 'site') {
            this.setState(prevState => ({
                siteUrl: {
                    ...prevState.siteUrl,
                    [name]: value
                }
            }));
        } else if (key === 'website') {
            this.setState(prevState => ({
                websiteUrls: {
                    ...prevState.websiteUrls,
                    [name]: value // Update specific website URL
                }
            }));
        }
    };

    updateCouponScanner = async (id) => {
        try {
            const store = this.state.store;
            const websiteUrls = this.state.websiteUrls[`website_urls_${id}`];
            const siteUrl = this.state.siteUrl[`site_url_${id}`];
            // convert websiteUrls to array
            let websiteUrlsArray = websiteUrls.split('\n').map(url => url?.split(',').map(url => url.trim())).flat();
            websiteUrlsArray = websiteUrlsArray.filter(url => url !== '');
            // check if websiteUrlsArray is empty
            // if (websiteUrlsArray.length === 0) {
            //     this.notify('error', "Website URLs cannot be empty");
            //     return;
            // }

            console.log({ 'method': "updateCouponScanner", id, store, websiteUrlsArray });
            // Add your update logic here
            const response = await fetch(`${API_URL}${url.COUPON_SCANNER}`, {
                method: 'put',
                headers: {
                    'Authorization': `${getBearToken()}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ scanner_website: id, website_urls: websiteUrlsArray, store, site_url: siteUrl })
            });
            if (response.ok) {
                this.notify('success', "Coupon scanner updated successfully");
            } else {
                this.notify('error', "Error updating coupon scanner");
            }

        } catch (err) {
            console.log(err)
            this.notify('error', "Error updating coupon scanner");
        }
    };

    async componentDidMount() {
        const { store } = this.props.location;
        this.setState({ store });

        $("#loadingIcon").text("Loading ...").css('color', 'red');

        try {
            const response = await fetch(`${API_URL}${url.COUPON_SCANNER}?store=${store}`, {
                method: 'get',
                headers: {
                    'Authorization': `${getBearToken()}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });
            const results = await response.json();

            if (response.ok) {
                const websiteUrlsState = {};
                const siteUrlState = {};
                results?.data.forEach(({ id, website_urls, site_url }) => {
                    websiteUrlsState[`website_urls_${id}`] = website_urls?.join(',\n') || '';
                    siteUrlState[`site_url_${id}`] = site_url || '';
                });

                this.setState({
                    siteUrl: siteUrlState,
                    websiteUrls: websiteUrlsState,
                    TotalCustomers: results?.data.length,
                    couponScanner: results?.data,
                    loading: true
                });
                this.notify('success', "Shoppers Loaded successfully");
            } else {
                this.handleErrorResponse(response, results);
            }
        } catch (err) {
            this.notify('error', "Auto BCL Deletion failed, Please check your network connection.");
        }
    }

    handleErrorResponse(response, results) {
        if (response.status === 401) {
            this.notify('error', `Unauthorized: ${results.message || "You are not authorized to perform this action"}`);
            window.location.href = "/logout";
        } else if (response.status === 500) {
            this.notify('error', `Server Error: ${results.message || "Something went wrong"}`);
        } else {
            this.notify('error', `Unexpected Error: ${response.status} - ${results.message || "Please try again"}`);
        }
    }

    render() {
        const { couponScanner, websiteUrls, siteUrl, store } = this.state;

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Veeper | AP - Customers</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs
                            title={this.props.t(`Coupon Scanner`)}
                            breadcrumbItem={this.props.t(`Coupon Scanner`)}
                        />
                        <ToastContainer />
                        <Row>
                            <Col>
                                <h5 style={{ fontWeight: 'lighter', fontSize: '14px', color: '#020' }}>{store}</h5>
                            </Col>
                            <Col className="d-flex justify-content-end">
                                <Link to={{ pathname: "/stores" }}>
                                    <Button className="btn btn-dark">
                                        <i className="fa fa-arrow-left"></i> Back to Stores
                                    </Button>
                                </Link>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <ScannerList
                                    couponScanner={couponScanner}
                                    onUpdate={this.updateCouponScanner} // Pass update function to ScannerList
                                    websiteUrls={websiteUrls}
                                    siteUrl={siteUrl}
                                    handleInputChange={this.handleInputChange}
                                />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

CouponScanner.propTypes = {
    t: PropTypes.any
};

export default withTranslation()(CouponScanner);